.spc-products{
    margin: 0 auto !important;
    min-height: 300px;
    position: relative;
}
@media (min-width: 1281px) {
    .spc-products{
        width: 1200px;
    }
}
@media (max-width: 1280px) {
    .home-page{
        padding-top: 130px;
    }
}