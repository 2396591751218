body{
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.theme-m1{
  --height-header: 56px;
  --color-primary: #4ca1af;
  --color-primary-bold: #25938c;
  --color-primary-bolder: #25938c;
  --color-primary-light:#c4e0e5;
  --color-div-hover:#9ce2ee;
  --color-primary-header: linear-gradient(5deg, var(--color-primary) 0%, var(--color-primary-light) 100%);
  --color-primary-app-header: linear-gradient(45deg, var(--color-primary) 0%, var(--color-primary-light) 100%);
  --color-warning: #f9b115;
  --color-info: #39f;
  --color-success: #2eb85c;
  --color-danger: #e55353;
  --color-dark: #636f83;
  --color-alert-msg: #856404;
  --color-alert-msg-bg: #fff3cd;
  --color-card-header: #3c4b64;
}
.theme-m1.theme-ajuma{
  --height-header: 56px;
  --color-primary: #486837;
  --color-toolbar: #9e7c54;
  --color-secondary: #9e7c54;
  --color-primary-dark: #745340;
  --color-primary-bold:#486837;
  --color-primary-bolder:#486837;
  --color-primary-bold-opacity: #486837cf;
  --color-secondary-opacity: #7453408f;
  --color-primary-light:#e8d4b2;
  --color-primary-lighter:#fff2db;
  --color-primary-header: #486837;
  --color-primary-app-header: #486837;
  --color-warning: #f9b115;
  --color-info: #39f;
  --color-success: #2eb85c;
  --color-danger: #e55353;
  --color-dark: #636f83;
  --color-alert-msg: #856404;
  --color-alert-msg-bg: #fff3cd;
  --color-card-header: #fff;
}
.theme-m1.theme-ajuma .btn-primary{
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.theme-m1.theme-ajuma .btn-primary:focus, .theme-m1.theme-ajuma .btn-primary:focus:active{
  box-shadow: 0 0 0 0.2rem rgb(72 104 55 / 35%);;
}
.theme-m1.theme-ajuma .btn-primary:active{
  background-color: var(--color-primary);
  box-shadow: 0 0 0 0.2rem rgb(72 104 55 / 35%);
  border-color: var(--color-primary);

}
.theme-m1.theme-ajuma .btn-link{
  color: var(--color-primary-bold);
}
.theme-m1.theme-ajuma .btn-outline-primary {
  color: var(--color-primary);
  border-color: var(--color-primary);
}
.ui-timepicker-div dl dd{
  margin: 0 10px 10px 40%;
}
.theme-m1 .fade{
  width: 100%;
  height: 100%;
}
.theme-m1 .c-main{
  padding: 0px;
  flex: none;
}

.theme-m1 .c-body{
  padding-top: var(--height-header);
  /* height: 300px; */
}

.theme-m1 .c-wrapper>header{
  position: fixed!important;
  top: 0;
  height: var(--height-header);
  flex-wrap: nowrap;
}


.mui-m_tab>.toolbar-bottom + .tab-content{
  position: absolute;
  top: var(--height-header);
  left: 0;
  right: 0;
  overflow: hidden;
}

.theme-m1 .container-fluid{
  padding-right: 0px;
  padding-left: 0px;
}


.theme-m1 header.c-header{
  background: var(--color-primary-app-header);
}
.theme-m1 header.c-header>.c-header-toggler>i{
  color: white;
  text-shadow: 1px 1px var(--color-primary);
}
.theme-m1 header.c-header>a.c-header-brand{
  font-weight: bold;
  font-size: 18px;
  color: white;
  margin-left: 0px!important;
}
.theme-m1 header.c-header>.c-header-nav{
  padding-right: 0px !important;
  padding-left: 5px !important;
}
.theme-m1 header.c-header>.c-header-nav>li>a.c-header-nav-link{
  padding: 0px !important;
}
.theme-m1 header.c-header>.c-header-nav>button{
  font-weight: bold;
  font-size: 18px;
  color: white;
}
.theme-m1 header.c-header>.c-header-nav>button:focus{
  box-shadow: none;
}
.theme-m1 header.c-header>.c-header-nav>button>i{
  text-shadow: 1px 1px var(--color-primary);
}

.theme-m1 .card{
  background: #fff;
    position: relative;
    border-radius: 4px;
    margin: 8px;
    border: 0px;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(90 168 181 / 10%);
}
.theme-m1 .card-header{
  min-height: 40px;
  color: var(--color-card-header);
  font-size: 16px;
  font-weight: 400;
  padding: 4px 4px;
  background: var(--color-primary-header);
  border: 0px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.theme-m1 .card-header::after{
  content: '';
    position: absolute;
    background-color: #fff;
    display: block;
    z-index: 15;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform-origin: 50% 100%;
    transform: scaleY(calc(1 /2));
    border-radius: 4px 4px 0 0;
}
.theme-m1 .c-wrapper{
  background: var(--color-primary-light);
}
.theme-m1 .touch-opacity:active{
  opacity: 0.5; /* Standard compliant browsers */
  -moz-opacity: 0.5; /* Firefox and Mozilla browsers */
  -webkit-opacity: 0.5; /* WebKit browser e.g. Safari */
  filter: alpha(opacity=50); /* For IE8 and earlier */
}
@media (max-width: 576px){
  .daterangepicker{
    height: calc(100% - 250px);
    overflow: scroll;
    margin: 8px;
  }
  .theme-m1.theme-ajuma .form-control .react-bootstrap-daterangepicker-container{
    font-size: 12px;
  }
}