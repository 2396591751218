.dropdown{
    position: relative;
}
.dropdown-content{
    position: absolute;
    top: 100%;
    z-index: 1021;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0.25rem;
    color: #4f5d73;
    background-color: transparent;
    border: none;
}
.dropdown-content.show{
    display: block;
}
