.header-left{
    position: relative;
    top: 0px;
    left: 0px;
    float: left;
    display: flex;
    align-items: center;
    height: 42px;
}
.header-right{
    position: relative;
    height: 42px;
    float: right;

}
.header-left-item{
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.header-right-item{
    float: right;
    position: relative;
    height: 40px;
}
.header-right-item button{
    color: var(--color-text-header);
    font-size: 12px;
    height: 100%;
}
.header-right-item button>span{
    color: var(--color-text-bold);
}
.header-right-item button>i{
    font-size: 12px;
    margin: 0px;
    height: auto;
}
.header-right-item button:hover{
    box-shadow: none;
}
.header-right-item button:hover>i{
    color: var(--color-primary);
}
.header-links-wrapper label{
    font-size: 12px;
    padding: 2px 0 2px 20px;
    line-height: 40px;
    height: 40px;
}
.cart-qty{
    margin: 0px 2px;
}
.header-right-item .flyout-cart{
    position: relative;
}
.header_1 .header-logo{
    padding: 0px;
    margin: 0 auto;
    width: 100%;
}
.dFaCjC{
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_1 .header-lower{
    position: relative;
}

/* .header_1{
    position: unset !important;

} */
.header_1 .sticky{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    box-shadow: 0 1px 10px 0 #969799;
    transition: s all ease
}
@media (min-width: 1281px) {
    .header-links-wrapper{
        margin: 0 0 0 10px !important;
    }
}