.product-selectors{
    display: flex;
    justify-content: space-between;
}
.product-selectors .left, .product-selectors.right{
    width: 50%;
}
.product-selectors .left .filtersPanel{
    background-color: transparent;
    border: 0px;
}
.product-selectors .left .block .title{
    /* display: none; */
    background-color: transparent;
    text-transform:capitalize;
}
.product-selectors .left .block .title>a{
    text-transform:capitalize;
    font-weight: 500;
    font-size: 13px;
    padding: 0;
}
.product-selectors .left .block{
    display: flex;
    align-items: center;
}
.product-selectors .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.product-selectors .left .sc_filter{
    width: 50%;
}
.product-selectors .left .sc_filter_color{
    width: 50%;
    height: 100%;
}
.product-selectors .left .sc_filter_color .filter-title{
    display: flex;
    align-items: center;
}
.product-selectors .left .filtersGroupPanel {
    margin: 1rem 0;
}
.product-selectors .left .priceRangeFilterPanel7Spikes .filtersGroupPanel{
    width: 100%;
}
.product-selectors .right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.product-selectors:after{
    content: none !important;
}
.product-selectors span{
    color: var(--color-text-bold);
}
.product-selectors  .left .filtersGroupPanel .input-range__track{
    background: #d6d6d6;
}
.product-selectors  .left .filtersGroupPanel .input-range__track--active{
    background-color: var(--color-secondary);
}
.product-selectors .sortOptionsDropDown, .product-selectors .productsPageSizeDropDown{
    width: 150px;
    margin: 0 0.5rem;
}
.product-selectors .product-sorting, .product-selectors .product-page-size{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
}
.product-selectors .Select-menu-outer{
    font-size: 13px;
    border: 0px;
    border-top: 1px solid #ddd;
    border-radius: 0px;
}
.product-selectors .Select-control{
    border: 1px solid #ddd;
    border-radius: 0px;
}
.product-selectors .Select.is-focused:not(.is-open)>.Select-control{
    border: 1px solid var(--color-secondary);
    box-shadow: none;
}
@media (max-width: 576px) {
    .product-selectors, .product-selectors .left, .product-selectors .right{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .product-selectors .left .sc_filter_color{
        width: 100%;
        padding: 0.5rem;
    }
    .search-box-search{
        padding: 0.5rem !important;
    }
    .product-selectors .product-sorting, .product-selectors .product-page-size{
        margin: 0.5rem 0.5rem;
    }
    .product-selectors .product-sorting > span, .product-selectors .product-page-size > span{
        width: 70px;
    }
    .product-selectors .sc_filter_color .filter-title{
        min-width: 70px;
        justify-content: center;
    }
    .product-selectors.type_1 .sc_filter {
        display: block;
        width: 100%;
        padding: 0.5rem;
    }
    .product-selectors .left .block .title{
        min-width: 70px;
    }
    .product-selectors .left .block{
        margin-bottom: 0;
    }
    .product-selectors .priceRangeFilterPanel7Spikes .filtersGroupPanel{
        padding: 0.5rem 2rem 0.5rem 1.6rem;
    }
    .product-selectors .nopAjaxFilters7Spikes{
        margin-bottom: 0;
    }
    .product-selectors .input-range__slider-container:last-child .input-range__label.input-range__label--value{
        left: 85% !important;
    }
    .product-selectors .input-range__label-container{
        left: -50%;
    }
}
@media (min-width: 769px){
    .product-selectors > div {
        margin: 0 !important;
    }
}
@media (min-width: 1281px) {
    .nopAjaxFilters7Spikes{
        margin: 0 !important;
    }
    .priceRangeFilterPanel7Spikes .filtersGroupPanel{
        padding: 20px !important;
    }
}
