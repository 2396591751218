
.footer-lower .social-sharing{
    margin: 2rem 0;
}
.footer-lower .social-sharing a{
    color: var(--color-text-bold);
    width: 24px;
    height: 24px;
}
.footer-lower .social-sharing li{
    margin: 0 12px;
}
.footer-lower .footer-disclaimer{
    border-top: 1px solid #e9e9e9;
    padding-top: 30px;
}
.footer .helper a{
    margin-left: 0.5rem;
}
.footer .bio p{
    margin: 16px 0;
    font-size: 13px;
    color: var(--color-text-bold);
}
.footer .bio .brand>img{
    transform: scale(0.5, 0.5);
}
@media (min-width: 1281px) {
    .footer-middle .center{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
    }
    .footer-block{
        float: none;
        margin-bottom: 2rem;
    }
    .footer-lower .social-sharing{
        float: none;
        line-height: normal;
    }
    .footer-lower .center{
        border: 0px !important;
    }
}