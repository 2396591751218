.search-bar {
    position: absolute !important;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0.5rem
}

.searchBox {
    background-color: rgba(0,0,0,.2);
    height: 40px;
    border-radius: 40px;
    transition: 2s all ease;
}
.searchBox:hover > .searchInput {
    width: 390px;
    padding: 10px !important;
    border: 0px
}

.searchBox:hover > .searchButton {
  background: white;
  color : var(--color-primary);
}

.searchButton {
    color: #fff;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.searchInput {
    border:none !important;
    background: none;
    outline:none;
    padding: 0 !important;
    color: hsla(0,0%,100%,.7) !important;
    font-size: 13px;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    height: 40px !important;

}
.searchBox .ui-autocomplete{
    top: 45px;
    left: 20px;
    width: 390px;
}
@media screen and (max-width: 620px) {
.searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
}
}