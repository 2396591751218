.overview{
    margin-left: 1rem !important;
}
.overview .shop-social{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.overview .shop-social>a{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    width: 37px;
    border: none;
    background-color: #f6f6f6;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
    margin-right: 0.5rem;
    color: var(--color-primary);
}
.overview .shop-social>a:last-child{
    margin-right: 0px;
}
.overview .shop-social>a:hover{
    background-color: var(--color-primary);
    color: #fff !important;
}
.overview .shop-social>a>i{
    font-size: 18px;
    margin-top: 2px;
}

@media (min-width: 1281px){
    .product-essential {
        display: flex;
        justify-content: space-between;
    }
    .product-essential .picture-link, .product-essential .picture-link img{
        height: 500px;
    }
}