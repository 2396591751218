figure.image-zoom  {
  width: 100%;
  background-repeat: no-repeat;
  cursor: crosshair;
}

figure.image-zoom:hover img {
  opacity: 0;
}

.image-zoom img {
  display: block;
  width: 100%;
  pointer-events: none;
}
