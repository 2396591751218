.sc_input_quantity{
    display: flex;
}
.sc_input_quantity .qty-input{
    width: 50px;
    height: 40px;
    text-align: center;
}
.sc_input_quantity>button{
    border: none;
    background-color: var(--color-secondary);
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
}
.sc_input_quantity .quantity-control>button {
    border: none;
    background-color: #ebedef;
    color: #503a20;
    outline: 1px solid transparent;
    padding: 2px 8px;
    margin-bottom: 1px;
    font-size: 13px;
}
.sc_input_quantity .quantity-control {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
}