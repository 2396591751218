.card_banner{
    min-height: 500px;
    max-height: 600px;
}
.card_banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (min-width: 1281px) {
    .home_topslide .mui-m_list_horizontal{
        margin-left: 0px !important;
    }
}
@media (max-width: 576px) {
    .card_banner{
        min-height: 200px;
        max-height: 200px;
    }
}