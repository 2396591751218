.c-main{
    padding-top: 0rem !important;
}
html{
    scroll-behavior: smooth;
    transition: .3s scroll-behavior ease;
}
.master-wrapper-content{
    padding-top: 2rem;
}
@media (min-width: 768px){
    .c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}