.item-select{
    cursor: pointer;
    background-color: #fff;
    padding: 10px;
    color: var(--color-text-bold);
    border-bottom: 1px solid #f6f6f6;
}
.item-select:hover{
    background-color:  var(--color-address-hover);
}
.item-select:last-child{
    border-bottom: 0px;
}
.select-shipping-address .address-grid{
    padding: 10px 0px;
}
.btn-select-address{
    min-width: 150px;
    border: none;
    border-radius: 0px;
    background-color: var(--color-secondary);
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
    /* float: right; */
    height: 30px;
}
.address-save{
    min-width: 150px;
    border: none;
    border-radius: 0px;
    font-size: 13px;
    font-weight: 700;
    color: var(--color-secondary);
    text-transform: uppercase;
    outline: 1px solid transparent;
    transition: all .2s ease;
    float: left;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}
.btn-select-address:focus, .btn-select-address:hover{
    box-shadow: none;
    color: #fff;
}
.address-save .form-check-input{
    position: relative;
    margin-right: 5px !important;
}
.theme-ajumashop input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.theme-ajumashop input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: var(--color-secondary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.shipping-addresses .sc_input_radio{
    width: 48.5%;
}
.shopping-cart-page .sc_login_type2 .lg>button{
    font-weight: 500 !important;
    width: 135px !important;
    font-size: 13px !important;
}
.shopping-cart-page .sc_login_type2 .lg{
    width: auto;
}