.theme-nrose,
.modal {
  --color-primary: #8b0016;
  --color-primary-bold: #8b0016;
  --color-secondary: #777777;
  --color-secondary-disabled: #77777733;
  --color-hotline: #777777;
  --color-danger: #e37373;
  --color-text-bold: #333;
  --color-active: #005232;
  --color-coupon: #ff1008;
  --color-coupon-background: #ffd0d947;
  --color-toolbar-admin-background: #8b00162e;
  --color-price: #333;
  --color-slide-button: #fa92a5cf;
  --color-product-attribute-cart: #fff;
  --color-product-attribute-cart-overlay: #f5f5f5;
  --color-border-light-secondary: #e9e9e9;
  --color-success: #80a682;
  --color-title-attribute-mobile: #f8b5c117;
  --color-active-wishlist: #e64f4f;
  --color-text-header: #898989
}
/* .theme-nrose,
.modal {
  --color-primary: #f8b5c1;
  --color-primary-bold: #fb7890;
  --color-secondary: #777777;
  --color-secondary-disabled: #77777733;
  --color-hotline: #f28d9f;
  --color-danger: #e37373;
  --color-text-bold: #333;
  --color-active: #80a682;
  --color-coupon: #ff1008;
  --color-coupon-background: #ffd0d947;
  --color-toolbar-admin-background: #f8b5c147;
  --color-price: #333;
  --color-slide-button: #fa92a5cf;
  --color-product-attribute-cart: #fff;
  --color-product-attribute-cart-overlay: #f5f5f5;
  --color-border-light-secondary: #e9e9e9;
  --color-success: #80a682;
  --color-title-attribute-mobile: #f8b5c117;
  --color-active-wishlist: #e64f4f;
} */
/* .theme-nrose,
.modal {
  --color-primary: #486837;
  --color-secondary: #503a20;
  --color-hotline: #745340;
  --color-danger: #e55353;
  --color-text-bold: #333;
  --color-active: #7bc11e;
  --color-coupon: #cd7000;
  --color-coupon-background: #f9eee0;
  --color-toolbar-admin-background: #879c7f;
} */

.mmd-msg > div > .modal {
  z-index: 1070;
}
.mmd-msg > div > .modal-backdrop {
  z-index: 1060;
}
.theme-nrose .slick-next {
  right: 10px;
}
.theme-nrose .slick-prev {
  left: 10px;
  z-index: 1;
}
.theme-nrose .slick-prev:before,
.theme-nrose .slick-next:before {
  font-size: 25px;
  color: var(--color-slide-button);
}
.theme-nrose .btn {
  border-radius: 0px;
}
.theme-nrose input[type="radio"]:checked:after {
  background-color: var(--color-primary-bold);
}
.theme-nrose input[type="radio"]:after,
.theme-nrose input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
.theme-nrose input[type="radio"]:after {
  background-color: #d1d3d1;
}
.theme-nrose button {
  outline: none;
}
.theme-nrose .spc-categories .product-grid .item-box:hover .buttons-lower button,
.theme-nrose .spc-categories .product-grid .item-box button.button-2:hover,
.theme-nrose .spc-categories .product-grid .item-box:hover .buttons-lower button span,
.theme-nrose .spc-categories .product-grid .item-box:hover .buttons-lower button i,
.theme-nrose .spc-categories .product-grid .item-box button.button-2:hover i
 {
  color: var(--color-primary);
  background-color: #f9f9f9;
}
.theme-nrose .spc-categories .product-grid .item-box .buttons-lower button{
  background-color: #f9f9f9;
}
.theme-nrose .spc-categories .product-grid .item-box input.button-2:hover {
  background-color: var(--color-primary);
}
.theme-nrose .product-grid .item-box:hover .buttons-lower button{
  background-color: var(--color-primary);
}
.theme-nrose .product-grid .item-box .buttons-lower button{
  background-color: #fff;
}
.theme-nrose .product-grid .item-box:hover .buttons-lower button span, .theme-nrose .product-grid .item-box:hover .buttons-lower button i{
  color: #fff;
  background-color: transparent;
}
.theme-nrose h2{
  line-height: normal
}
.theme-nrose .sc_input_quantity.default > button {
  border: none;
  background-color: #ebedef;
  padding: 5px 10px;
  height: 35px;
  width: 35px;
  color: var(--color-secondary);
  text-transform: uppercase;
  outline: 1px solid transparent;
  transition: all 0.2s ease;
}
.theme-nrose .sc_input_quantity.default >input {
  height: 35px;
}
.theme-nrose .modal-header{
  font-size: 15px;
}
.theme-nrose .btn-success {
  color: #fff;
  background-color: var(--color-success);
  border-color: var(--color-success);
}
.theme-nrose .slick-next:before, .theme-nrose .slick-prev:before{
  content: none;
}
.theme-nrose .slick-next{
  background: url(/images/ajuma/arrow-next.png) no-repeat;
  background-position: -8px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  transition: all .2s ease;
}
.theme-nrose .slick-prev{
  background: url(/images/ajuma/arrow-prev.png) no-repeat;
  background-position: -45px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  transition: all .2s ease;
}