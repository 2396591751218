.product-box {
  position: relative;
  width: 100%;
  float: left;
  margin: 0 0 55px;
  background-color: #fff;
}
.product-box .picture {
  margin: 0 0 25px;
  position: relative;
}
.product-box .picture a {
  display: block;
  position: relative;
  overflow: hidden;
  height: 300px;
}
.product-box .picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-box .details {
  padding: 0 0.5rem 0.5rem;
}
.product-box .product-title {
  display: block;
  font-size: 14px;
  color: var(--color-text-bold);
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}
.product-box .product-rating-box {
  display: inline-block;
  margin: 0;
}
.product-box .rating {
  background: url(/images/ajuma/rating-sprite.png) 0 0 repeat-x;
  width: 80px;
  height: 13px;
}
.product-box .rating div {
  background: #ffc600 url(/images/ajuma/rating-sprite.png) 0 100% repeat-x;
  height: 13px;
}
.product-box .prices {
  overflow: hidden;
  font-weight: bold;
  font-size: 15px;
  color: var(--color-price);
  margin: 0;
  display: block;
}
.product-box .buttons-lower {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.product-box .buttons-lower button {
  outline: none;
  border: 0;
  color: #888;
  background-color: #f9f9f9;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 0 3px 0 0;
  transition: 0.2s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-box .buttons-lower button > span {
  display: inline-block;
  position: relative;
  height: 18px;
  background-color: transparent;
  padding: 0 0 0 10px;
  font-size: 13px;
  font-weight: 700;
  color: #777;
  text-transform: uppercase;
  transition: all 0.2s ease;
}
.product-box .buttons-lower .ajax-cart-button-wrapper,
.product-box .buttons-lower .ajax-cart-button-wrapper > button {
  width: 100%;
}
.product-box .buttons-lower .ajax-cart-button-wrapper > button:hover {
  background-color: var(--color-primary);
  color: #fff;
}
.product-box .buttons-lower .ajax-cart-button-wrapper > button:hover > span {
  color: #fff;
}
.product-box .add-to-wishlist-button.active {
  color: var(--color-active-wishlist);
}
@media (min-width: 481px) {
  .product-grid .details .prices {
    display: block !important;
  }
}
@media (min-width: 1281px) {
  .product-grid .product-box {
    width: 23.5%;
    margin-left: 2%;
  }
  .product-grid .product-box:nth-child(4n + 1) {
    clear: both;
    margin-left: 0;
  }
}
@media (max-width: 1280px) {
  .product-grid .product-box:nth-child(4n + 1) {
    clear: both;
    margin-left: 0;
  }
  .product-grid .product-box {
    width: 25%;
  }
  .spc-products {
    padding: 0 1rem !important;
  }
  .product-box {
    background-color: transparent;
  }
  .product-box .product-item {
    margin: 0.5rem;
    background-color: #fff;
  }
  .footer-lower {
    text-align: center;
  }
}
@media (max-width: 769px) {
  .product-grid .product-box:nth-child(3n + 1) {
    clear: both;
    margin-left: 0;
  }
  .product-grid .product-box {
    width: 33%;
  }
  .spc-products {
    padding: 0 1rem !important;
  }
  .product-box {
    background-color: transparent;
  }
  .product-box .product-item {
    margin: 0.5rem;
    background-color: #fff;
  }
}
@media (max-width: 576px) {
  .product-grid .product-box:nth-child(n + 1) {
    margin-left: 0.75rem;
  }
  .product-grid .product-box {
    width: 46%;
  }
  .product-box .product-item {
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: auto !important;
    position: relative;
    margin: 0;
  }
  .product-box .picture a {
    height: 150px;
  }
  .product-box .picture {
    width: 100% !important;
    padding: 2.5rem 0.5rem 0.5rem 0.5rem !important;
  }
  .product-box {
    margin-bottom: 0.75rem;
    border-radius: 10px;
    overflow: hidden;
  }
  .product-box .details {
    width: 100% !important;
    padding: 45px 10px 10px 10px !important;
  }
  .product-box .details .add-info .buttons-lower {
    position: absolute !important;
    top: 0;
    left: 0;
    margin-top: 0;
    width: 100% !important;
    justify-content: space-between;
  }
  .product-box .ajax-cart-button-wrapper {
    float: right;
  }
  .product-box .buttons-lower .ajax-cart-button-wrapper,
  .product-box .buttons-lower .ajax-cart-button-wrapper > button {
    width: 36px;
  }
  .product-grid .details .prices {
    margin-bottom: 0 !important;
  }
  .product-box .buttons-lower button > span {
    display: none;
  }
  .product-box .buttons-lower .ajax-cart-button-wrapper > button:hover {
    background-color: transparent;
    color: var(--color-primary);
  }
  .product-box .product-title,
  .product-box .prices {
    font-size: 13px;
  }
  .product-box .product-title{
      height: auto !important;
  }
  .product-box .product-title>a{
      white-space: pre-line !important;
  }
  .spc-products {
    padding: 0 !important;
  }
  .item-grid {
    display: flex;
    flex-wrap: wrap;
  }
}
