.sc_navbar{
    width: 100%;
    position: static;
    text-align: left;
    z-index: 1010;
}
.sc_navbar .menu{
    padding: 0;
    margin: 0 auto;
    font-size: 0;
    text-align: center;
}
.sc_navbar .menu>li{
    display: inline-block;
    vertical-align: middle;
    position: static;
}
.sc_navbar .menu>li>a{
    display: block;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.sc_navbar .menu>li>a:hover{
    color: #fff !important;
}
.sc_navbar .menu-dropdown{
    display: none;
    left: 0;
    right: 0;
    width: 100%;
    padding: 21px 10px 10px;
    position: absolute;
    overflow: hidden;
    z-index: 3;
}
.sc_navbar .menu-dropdown.show{
    display: block;
}
.sc_navbar .menu-content{
    background: #fff;
    box-shadow: 0 0 15px rgb(150 150 150 / 30%);
    padding: 30px;
    color: var(--color-text-bold);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.sc_navbar .menu-content .menu-block{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 55px;
}
.sc_navbar .menu-content .menu-block:first-child{
    margin-left: 0px;
}
.sc_navbar .menu-content .menu-block>a{
    font-weight: bold;
    color: var(--color-text-bold);
    border-bottom: 1px solid #f1f1f1;
    width: 100%;
    text-align: center;
    padding-bottom: 0.5rem;
}
.sc_navbar .menu-content .menu-block a:hover{
    color: var(--color-primary) !important;
}
.sc_navbar .menu-content .sub-menu{
    margin-top: 0.5rem;
    width: 100%;
}
.sc_navbar .menu-content .sub-menu>li>a{
    color: #777;
}
.sc_navbar .menu-content .sub-menu>li{
    text-align: center;
}

@media (min-width: 1281px) {
    .sc_navbar .menu-content .menu-block{
        width: 15%;
    }
}