.sc_filter_color{
    padding: 0 3rem;
    width: 100%;
    display: flex;
}
.sc_filter_color .filter-title{
    width: fit-content;
    font-size: 13px;
}
.sc_filter_color .filter-range{
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sc_filter_color .filter-value{
    width: 20px;
    height: 20px;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid var(--color-secondary);
}
.sc_filter_color .filter-value>div{
    width: 16px;
    height: 16px;
    border-radius: 50px;
    cursor: pointer;
}