#overlay-season {
    /* background-color: #f1f1f1;
    position: absolute;
    width: 100%;
    height: calc(100% - 40px); */
    background-color: transparent;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1021;
    pointer-events: none;
  }
  
  .petal {
    background: url(/images/ajuma/apricot-blossom.png) no-repeat;
  }
  .petal.petal-style1 {
    width: 45px;
    height: 20px;
    background-position: -31px 0;
  }
  .petal.petal-style2 {
    width: 42px;
    height: 22px;
    background-position: 0 -24px;
}
  .petal.petal-style3 {
    width: 37px;
    height: 24px;
    background-position: 0 -50px;
}
  .petal.petal-style4 {
    width: 26px;
    height: 34px;
    background-position: -49px -35px;
}
.petal2 {
    background: url(/images/ajuma/cherry-blossom.png) no-repeat;
  }
.petal2.petal2-style1 {
    width: 45px;
    height: 20px;
    background-position: -31px 0;
  }
  .petal2.petal2-style2 {
    width: 42px;
    height: 22px;
    background-position: 0 -24px;
}
  .petal2.petal2-style3 {
    width: 37px;
    height: 24px;
    background-position: 0 -50px;
}
  .petal2.petal2-style4 {
    width: 26px;
    height: 34px;
    background-position: -49px -35px;
}
.banner-top{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.top-left, .top-right{
  width: 50%;
}